<!-- top menu mobile -->
<div *ngIf="showMobile" class="z-20 sticky block sm:hidden bg-white mt-0 top-0"> <!-- [ngClass]="isIOS ? 'mt-10 top-10' : 'mt-0 top-0'"-->
    <div *ngIf="isIOS" class="h-14 bg-white"></div>

    <div *ngIf="showBackBtn || accountUser" class="flex justify-between p-1 px-2">

        <!-- back btn -->
        <span *ngIf="!showBackBtn"></span>
        <button *ngIf="showBackBtn" type="button" (click)="goBack()" class="p-2 text-gray-500">
            <svg class="w-5 h-5 stroke-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        </button>


        <!--
        <div class="text-base font-medium">
            {{ titleMobile }}
        </div>
        -->

        <div class="flex gap-4 items-center">
            <!-- share profile -->
            <button *ngIf="accountUser" (click)="shareProfileModule.open()">
                <svg class="w-6 h-6 stroke-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                </svg>
            </button>

            <!-- messenger -->
            <span *ngIf="accountUser" class="relative">
                <span class="flex justify-between items-center">
                    <a [routerLink]="['/chat/messages']" *ngIf="accountUser.getChatUnreadMessagesCount() > 0" class="cursor-pointer absolute bottom-auto left-auto right-3 top-3 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-500 px-2 py-1 text-center align-baseline font-medium leading-none text-white hover:text-white" style="font-size: 10px">
                        {{ accountUser.getChatUnreadMessagesCount() }}
                    </a>
                    <button type="button" [routerLink]="['/chat/messages']" class="p-2 text-gray-500">
                        <svg class="w-6 h-6 stroke-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                        </svg>
                    </button>
                </span>
            </span>
        </div>

    </div>

</div>



<!-- top menu desktop -->
<div *ngIf="showDesktop" #headerContainer class="bg-white sticky top-0 z-20 hidden sm:block">
    <div *ngIf="isIOS" class="h-4 bg-white"></div> <!-- ipad needs space for ipados clock etc -->

    <!-- mobile side menu -->
    <!--
    <div class="h-9 bg-white">&nbsp;</div>
    <div class="relative z-40 md:hidden" role="dialog" aria-modal="true" *ngIf="mobileMainMenu">
        <div class="fixed inset-0 bg-black bg-opacity-25"></div>

        <div class="fixed inset-0 z-40 flex">
            <div class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div class="flex px-4 pt-5 pb-2">
                    <button (click)="mobileMainMenu=!mobileMainMenu" type="button" class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400">
                        <span class="sr-only">Close menu</span>
                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div class="space-y-6 py-1 px-4" *ngIf="selectedCompany && accountUser && (isCompanyUser() || isStaff())">
                    <app-company-menu [company]="selectedCompany" [accountUser]="accountUser" (navigated)="mobileMainMenu=false"></app-company-menu>
                </div>

                <div class="space-y-6 py-1 px-4" *ngIf="accountUser">
                    <app-home-menu [accountUser]="accountUser"></app-home-menu>
                </div>

                <div class="space-y-6 border-t border-gray-200 py-6 px-4" *ngIf="!accountUser">
                    <div class="flow-root">
                        <a (click)="openSignupModal()" class="-m-2 block p-2 font-medium text-gray-900">Sign up</a>
                    </div>
                    <div class="flow-root">
                        <a (click)="openLoginModal()" class="-m-2 block p-2 font-medium text-gray-900">Log in</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    -->

    <!-- promote banner top NOTE false -->
    <div *ngIf="false && promoteBannerTop && !accountUser && !isNativePlatform" class="overflow-hidden flex justify-between bg-gray-50 border-b px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div class="w-5"></div>
        <div class="flex flex-wrap items-center justify-center gap-x-4 gap-y-2">
            <p class="text-sm leading-6 text-gray-900">
                <strong class="font-medium">Your career</strong>, right from your pocket
            </p>
            <a *ngIf="!accountUser" href="/signup" class="flex-none btn-blue-xs">Sign up</a>
            <!--
            <a href="#" class="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Install App</a>
            -->
        </div>
        <div class="w-5 flex flex-1 justify-end">
            <button (click)="promoteBannerTop = false" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
            </button>
        </div>
    </div>

    <!-- top menu desktop -->
    <header *ngIf="showDesktop" class="relative hidden sm:block">
        <nav aria-label="Top">

            <!-- Navigation -->
            <div class="bg-white mx-auto max-w-screen-2xl">
                <div class="px-4 sm:px-6 md:px-8">
                    <div>
                        <div class="flex h-16 items-center justify-between">

                            <!-- left side -->
                            <div class="flex-none sm:flex-1">

                                <!-- Mobile Menu Toggle -->
                                <div class="flex gap-3 items-center lg:hidden">

                                    <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                                    <button (click)="mobileMainMenu=!mobileMainMenu" type="button" class="-ml-2 rounded-md bg-white p-2 text-gray-400" *ngIf="false"><!-- note false -->
                                        <span class="sr-only">Open menu</span>
                                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                        </svg>
                                    </button>

                                    <!-- note false -->
                                    <a *ngIf="displayWarningSubscriptionId && false" class="link-btn in-nav-mobile-link block lg:hidden" style="cursor: pointer; color: #dc3545;" [routerLink]="['/service/subscription/' + displayWarningSubscriptionId]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                        </svg>
                                    </a>

                                    <!-- Logo Small -->
                                    <a [routerLink]="['']" class="block sm:hidden">
                                        <span class="sr-only">HiCareer</span>
                                        <img src="assets/img/logo-hi.png" alt="" class="h-10 w-auto">
                                    </a>

                                </div>

                                <!-- Logo -->
                                <div class="hidden sm:block flex items-center">
                                    <a [routerLink]="['']">
                                        <span class="sr-only">HiCareer</span>
                                        <img class="h-10 w-auto" src="assets/img/logo-hicareer.png" alt="">
                                    </a>
                                </div>

                            </div>

                            <!-- center -->
                            <div class="flex-1 flex justify-center hidden lg:block">

                                <!--
                                <div class="flex gap-4 items-center">
                                    <div class="hidden md:block">
                                        <app-home-top-menu [loggedInAccountUser]="accountUser" [selected]="selectedTopNav"></app-home-top-menu>
                                    </div>
                                </div>
                                -->

                                <!-- Search -->
                                <div class="flex justify-center">

                                  <!--
                                    <div>
                                        <label for="simple-search" class="sr-only">Search</label>
                                        <div class="relative w-full">
                                            <div class="absolute inset-y-0 left-0 flex items-center pl-7 pointer-events-none">
                                                <svg aria-hidden="true" class="w-4 h-4 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </div>
                                            <input type="text" [(ngModel)]="searchField" (keydown.enter)="doSearch()" class="w-32 sm:w-56 border border-gray-100 bg-gray-100 rounded-full m-3 px-3 py-2 pl-10 text-sm placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500" placeholder="Search people..." />
                                        </div>
                                    </div>
                                  -->

                                </div>

                            </div>


                            <!-- right side -->
                            <div class="flex-1 flex items-center justify-end">
                                <div class="flex items-center md:ml-8">
                                    <div class="flex items-center gap-x-4 md:gap-x-6">
                                        <!--
                                            <div class="hidden lg:flex">
                                            <a href="#" class="-m-2 p-2 text-gray-400 hover:text-gray-500">
                                            <span class="sr-only">Search</span>
                                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                            </a>
                                            </div>

                                        -->

                                        <a *ngIf="!isNativePlatform" class="link-btn text-sm font-normal text-gray-700 p-1 cursor-pointer" (click)="forBusinessModal.open()">
                                          {{ 'For Business' | translate }}
                                          <!--
                                            <span class="hidden sm:block">For Business</span>
                                            <span class="block sm:hidden">Business</span>
                                          -->
                                        </a>

                                        <!--
                                        <a *ngIf="accountUser" class="link-btn in-nav-mobile-link hidden md:block p-1" [routerLink]="['/karma']">
                                            <span class="text-green-600 text-sm"><i class="bi bi-arrow-up-right-circle"></i> {{ accountUser.getKarma() }}</span>
                                        </a>
                                        -->

                                        <a *ngIf="displayWarningSubscriptionId" class="link-btn in-nav-mobile-link" style="cursor: pointer; color: #dc3545;" [routerLink]="['/service/subscription/' + displayWarningSubscriptionId]">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                            </svg>
                                        </a>

                                        <!-- notifications -->
                                        <div class="relative inline-flex w-fit hidden sm:block">
                                            <div *ngIf="notificationUnreadCount > 0" (click)="openNotifications()" class="cursor-pointer absolute bottom-auto left-auto right-1 top-1 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-500 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
                                                {{ notificationUnreadCount }}
                                            </div>
                                            <button *ngIf="accountUser" class="link-btn in-nav-mobile-link p-1 text-gray-600" (click)="openNotifications()">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                                </svg>
                                            </button>
                                        </div>

                                        <!-- messenger -->
                                        <div class="relative inline-flex w-fit sm:block" *ngIf="accountUser">
                                                <div *ngIf="accountUser.getChatUnreadMessagesCount() > 0" class="cursor-pointer absolute bottom-auto left-auto right-1 top-1 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-500 px-2 py-1 text-center align-baseline text-xs font-bold leading-none text-white">
                                                    {{ accountUser.getChatUnreadMessagesCount() }}
                                                </div>
                                                <button type="button" [routerLink]="['/chat/messages']" class="link-btn in-nav-mobile-link p-1 text-gray-600">
                                                    <svg class="w-6 h-6 stroke-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                                                    </svg>
                                                </button>

                                                <!--
                                                <div *ngIf="dropdownMessenger" class="absolute divide-y divide-gray-100 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                                    <div class="py-1" role="none">
                                                        <a [routerLink]="['/chat/messages']" (click)="dropdownMessenger=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Messenger</a>
                                                    </div>
                                                    <div class="py-1" role="none">
                                                        <a [routerLink]="['/chat/messages/support']" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-3">Career Support</a>
                                                    </div>
                                                </div>
                                                -->
                                        </div>

                                        <!-- profile -->
                                        <div class="flex-none relative" *ngIf="accountUser">
                                            <div>
                                                <!-- profile img -->
                                                <button
                                                        *ngIf="accountUser.getMainImage() && accountUser.getMainImage().getImg()"
                                                        (click)="dropdownProfile=!dropdownProfile"
                                                        type="button"
                                                        class="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                        id="user-menu-button"
                                                        aria-expanded="false"
                                                        aria-haspopup="true">
                                                    <span class="sr-only">Open user menu</span>
                                                    <img class="h-8 w-8 object-cover rounded-full" src="{{ accountUser.getMainImage().getImg() }}" alt="">
                                                </button>
                                                <!-- avatar -->
                                                <button *ngIf="!(accountUser.getMainImage() && accountUser.getMainImage().getImg())" (click)="dropdownProfile=!dropdownProfile" type="button" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                                    <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                                                        <span class="font-medium text-gray-600">{{ accountUser.getInitials() || 'Hi' }}</span>
                                                    </div>
                                                </button>
                                            </div>

                                            <div *ngIf="dropdownProfile" class="absolute divide-y divide-gray-100 right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                                <div class="px-4 py-3" role="none">
                                                    <a [routerLink]="['/hi/' + accountUser.getSlug()]" (click)="dropdownProfile=false" role="menuitem" tabindex="-1" id="user-menu-item-1">
                                                        <p class="truncate text-sm font-medium text-gray-900" role="none">{{ accountUser.getFullName() }}</p>
                                                        <p class="truncate text-xs text-gray-900" role="none">{{ accountUser.getEmail() }}</p>
                                                        <p class="btn-outline-blue-sm mt-2 w-full">View profile</p>
                                                    </a>
                                                </div>
                                                <div class="py-1" role="none">
                                                    <a [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/connections']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Connections</a>
                                                    <a [routerLink]="['/hi/' + accountUser.getSlug() + '/tab/viewers']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Profile viewers</a>
                                                    <!-- karma -->
                                                    <a [routerLink]="['/karma']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">
                                                        <span class="text-green-600 text-base"><i class="bi bi-arrow-up-right-circle"></i> {{ accountUser.getKarma() }}p</span> karma
                                                    </a>
                                                </div>
                                                <div class="py-1" role="none">
                                                    <!-- Active: "bg-gray-100", Not Active: "" -->
                                                    <!--<a [routerLink]="['/me/dashboard']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Dashboard</a>-->
                                                    <a [routerLink]="['/service/premium']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Premium</a>
                                                    <a [routerLink]="['/me/settings']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Settings</a>
                                                    <!--<a [routerLink]="['/me/dashboard']" (click)="dropdownProfile=false" [queryParams]="{ p: 'payments'}" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-3">Payments</a>-->
                                                </div>
                                                <div class="py-1" role="none">
                                                    <a [routerLink]="['/terms-of-service']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-3">Terms of Service</a>
                                                    <a [routerLink]="['/privacy-policy']" (click)="dropdownProfile=false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-3">Privacy Policy</a>
                                                </div>
                                                <div class="py-1" role="none">
                                                    <button (click)="dropdownProfile=false; logout();" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-3">Sign out</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="relative ml-3" *ngIf="!accountUser">
                                            <button (click)="openLoginModal()" type="button" class="btn-outline-primary">Log in</button>
                                        </div>

                                    </div>


                                    <!--
                                        <span class="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true"></span>

                                        <div class="flow-root">
                                            <a href="#" class="group -m-2 flex items-center p-2">
                                                <svg class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                                </svg>
                                                <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span>
                                                <span class="sr-only">items in cart, view bag</span>
                                            </a>
                                        </div>
                                    -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>

    <div *ngIf="showMobile || showDesktop" class="hidden sm:block">
      <app-header-sub-menu></app-header-sub-menu>
    </div>

</div>


<!-- show invites alert -->
<div *ngIf="accountUser && accountUser.hasInvites()" class="mx-auto max-w-xl">
    <div *ngFor="let inv of receivedInvites" class="mt-3 rounded-md bg-blue-50 p-8 border border-blue-200">
        <div *ngIf="inv.getCompanyId()" style="display: grid">
            <span>You have been invited by {{ inv.getFromAuFullName() }} to join {{ inv.getCompanyName() }}</span>
            <span class="mt-3">
                <button class="btn btn-sm btn-primary" (click)="acceptInvite(inv)">{{ 'accept' | translate }}</button>
                <!--<button class="btn btn-sm btn-secondary" (click)="ignoreInvite(inv)">{{ 'ignore' | translate }}</button>-->
            </span>

        </div>
        <div *ngIf="inv.getJobId()" style="display: grid">
            <span>You have been invited by {{ inv.getFromAuFullName() }} to apply to the job {{ inv.getJobTitle() }}</span>
            <span class="mt-3">
                <button class="btn btn-sm btn-primary" [routerLink]="['/' +inv.getPageSlug()+ '/job/' + inv.getJobId() ]">{{ 'view' | translate }}</button>
                <button class="btn btn-sm btn-secondary" (click)="ignoreInvite(inv)">{{ 'ignore' | translate }}</button>
            </span>

        </div>
        <div *ngIf="inv.getType() == 'become_talent_agent'" style="display: grid">
            <span>You have been invited by {{ inv.getFromAuFullName() }} to become a Talent Agent</span>
            <span class="mt-3">
                <button class="btn btn-sm btn-info" [routerLink]="['/agent-invite']">{{ 'read-more' | translate }}</button>
                <button class="btn btn-sm btn-secondary" (click)="ignoreInvite(inv)">{{ 'ignore' | translate }}</button>
            </span>
        </div>
    </div>
</div>

<!-- show push notification alert -->
<div *ngIf="isNativePlatform && !allowesPushNotifications" class="mx-auto max-w-xl">
    <div class="my-3 rounded-md bg-blue-50 p-8 border border-blue-200">
        <div class="grid">
            <span>Enable push notifications to receive important updates.</span>
            <span class="mt-3">
                <button class="btn btn-sm btn-primary" (click)="openNotificationSettings()">{{ 'Enable' | translate }}</button>
            </span>
        </div>
    </div>
</div>






<!-- note false -->
<div *ngIf="!hideHeader && false" class="top-nav-bar">

    <a class="logo" [routerLink]="['']">
        <img src="assets/img/logo-hicareer.png" alt="">
    </a>

    <div class="search">
        <!--
        <app-search-field *ngIf="!hideSearch"></app-search-field>
        -->
    </div>

    <!--
        <div class="search">
        <app-search-main [keywords]="keywords" [location]="location" *ngIf="!hideSearch"></app-search-main>
        </div>
    -->

    <div class="actions" *ngIf="!accountUser">
        <!--
            <a class="link-btn btn-signup" style="margin-right: 5px" [routerLink]="['/employer']">{{ 'word.Employer' | translate }}</a>
            <a class="link-btn btn-signup" style="margin-right: 5px" [routerLink]="['/recruiter']">{{ 'word.Recruiter' | translate }}</a>
        -->
        <button class="btn btn-outline-primary mr-2" [routerLink]="['/employer']">{{ 'Employer' | translate }}</button>
        <button class="btn btn-outline-primary btn-login" (click)="openLoginModal()">{{ 'login.login' | translate }}</button>

        <div class="btn-mobile">
            <button class="btn-menu " data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-person"></i>
            </button>
            <ul class="dropdown-menu">
                <li><button class="dropdown-item" (click)="openLoginModal()">{{ 'login.login' | translate }}</button></li>
                <!--
                    <li><button class="dropdown-item" [routerLink]="['/recruiter']">{{ 'word.Recruiter' | translate }}</button></li>
                    <li><button class="dropdown-item" [routerLink]="['/employer']">{{ 'word.Employer' | translate }}</button></li>
                -->
            </ul>
        </div>

    </div>

    <div class="actions" *ngIf="accountUser">

        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'profiles' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'profiles', sc: selectedCompanyId }">{{ 'Profiles' | translate }}</a>
        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'jobs' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'jobs', sc: selectedCompanyId }">{{ 'company.jobs' | translate }}</a>
        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'projects' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'projects', sc: selectedCompanyId }">{{ 'company.projects' | translate }}</a>
        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'career-page' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'career-page', sc: selectedCompanyId }">{{ 'company.career-page' | translate }}</a>
        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'services' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'services', sc: selectedCompanyId }">{{ 'company.services' | translate }}</a>
        <a *ngIf="selectedCompanyName" class="link-employer-btn" [ngClass]="{ 'selected': pp == 'settings' }" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company', pp: 'settings', sc: selectedCompanyId }">{{ 'word.Team' | translate }}</a>

        <!--business menu-->
        <a class="in-nav-mobile-link link-employer-btn" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div *ngIf="!selectedCompanyIcon">
                <div *ngIf="!selectedCompanyName">{{ 'word.Employer' | translate }}</div>
                <div *ngIf="selectedCompanyName" class="profile-employer-circle" title="{{ selectedCompanyName }}">{{ selectedCompanyNameTwoLetter }}</div>
            </div>
            <div *ngIf="selectedCompanyIcon" class="profile-image">
                <app-image [image]="getImage(selectedCompanyIcon)" [ref]="'icon30'" [cache]="true"></app-image>
            </div>

        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 2000">
            <div *ngFor="let company of accountUser.getCompanies()">
                <li>
                    <a class="dropdown-item" (click)="setMasterCompany(company)" [routerLink]="['/me/dashboard']" [queryParams]="{ sc: company.id, p: 'company' }">
                        <!--
                            <span [ngClass]="{'empty30-icon': !company.page_icon}">
                            <app-image *ngIf="company.page_icon" [image]="getImage(company.page_icon)" [ref]="'icon30'" [cache]="true"></app-image>
                            </span>
                        -->
                        {{ company.name || "Company" }}
                    </a>
                </li>
            </div>
            <li *ngIf="accountUser.getCompanies().length > 0"><hr class="dropdown-divider"></li>
            <li>
                <button class="dropdown-item" (click)="openAddCompanyModal()">
                    {{ 'company.add-company' | translate }}
                </button>
            </li>
            <!--
                <li>
                <a class="dropdown-item" [routerLink]="['/employer']">{{ 'word.employer' | translate }}</a>
                </li>
            -->
        </ul>

        <!--<a class="right-nav" [routerLink]="['/employer']">{{ 'employer' | translate }}</a>-->

        <!--staff menu-->
        <a *ngIf="accountUser && isStaff()" class="in-nav-mobile-link link-btn" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-gear"></i>
        </a>
        <ul *ngIf="accountUser && isStaff()" class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 2000">
            <li><h6 class="dropdown-header">Staff</h6></li>
            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'staff-overview'}">
                    Overview
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'page-manager'}">
                    Companies
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'profile-manager'}">
                    Profiles
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/chat/messages/']" [queryParams]="{ lsa: 'true'}">
                    Conversations
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/testing/templates/']">
                    Test Templates
                </a>
            </li>

            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'referral-stats'}">
                    Referral Reports
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'referral-clients'}">
                    Referral Clients
                </a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/job-manager']">
                    Jobs (adm)
                </a>
            </li>
        </ul>

        <!--
            <a class="link-btn btn-signup" style="margin-right: 5px" [routerLink]="['/recruiter']">{{ 'word.Recruiter' | translate }}</a>
        -->

        <!--
            <a class="right-nav" style="cursor: pointer;" (click)="openFollowing=!openFollowing">
            <i class="bi bi-heart"></i>
            </a>
            <div *ngIf="openFollowing" class="following-menu">
            <app-company-list [showPublic]="true" [followerAccountUserId]="accountUser.getId()"></app-company-list>
            </div>

            <button class="btn btn-outline-dark" style="margin-right: 5px" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'company-new'}">{{ 'word.Employer' | translate }}</button>

            <a class="link-btn in-nav-mobile-link chat-btn" (click)="accountUser.chat_unread_messages = 0" style="cursor: pointer;" [routerLink]="['/chat/messages']">
            <i class="bi bi-chat"></i>
            <span class="badge-count" *ngIf="accountUser.getChatUnreadMessagesCount() > 0">{{ accountUser.getChatUnreadMessagesCount() }}</span>
            </a>

            <a class="link-btn in-nav-mobile-link" [routerLink]="['/me/dashboard']"><i class="bi bi-grid"></i></a>
        -->

        <a class="link-btn btn-mobile" (click)="openMenuModal()"><i class="bi bi-list"></i></a>

        <!--
            <a class="right-nav in-nav-mobile-link" style="cursor: pointer;" (click)="navigateToEarningsPage()">
            <i class="bi bi-wallet2"></i>
            </a>
        -->

        <!--general menu-->
        <a class="in-nav-mobile-link link-btn" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person"></i>
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 2000">
            <li><h6 class="dropdown-header">{{ accountUser.getEmail() }}</h6></li>

            <!--
                <li class="in-menu-mobile-link" *ngIf="accountUser.getBalanceAvailable() && accountUser.getCurrencyCode()">
                <a class="dropdown-item" style="cursor: pointer;" (click)="navigateToEarningsPage()">{{ accountUser.getCurrencyCode() }} {{ accountUser.getBalanceAvailable() | number:'1.0-0' }}</a>
                </li>
                <li>
                <a class="dropdown-item" (click)="navigateToEarningsPage()" style="cursor: pointer;">
                {{ 'Balance' | translate }}
                {{ accountUser.getCurrencyCode() }} {{ accountUser.getBalanceAvailable() | number:'1.0-0' }}
                </a>
                </li>
            -->

            <li>
                <a class="dropdown-item" [routerLink]="['/me/dashboard']">{{ 'profile.Dashboard' | translate }}</a>
            </li>
            <li>
                <a class="dropdown-item" [routerLink]="['/hi/' + accountUser.getSlug()]">{{ 'dashboard.Profile' | translate }}</a>
            </li>
            <!--
                <li><a class="dropdown-item" [routerLink]="['/me/applications']">{{ 'applications' | translate }}</a></li>

                <li><a class="dropdown-item" [routerLink]="['/invites']">{{ 'invites' | translate }}</a></li>
            -->

            <li><a class="dropdown-item" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'settings'}">{{ 'dashboard.Settings' | translate }}</a></li>
            <li><a class="dropdown-item" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'payments'}">{{ 'recruiter.Payments' | translate }}</a></li>

            <li><hr class="dropdown-divider"></li>

            <li><button class="dropdown-item" (click)="logout()">{{ 'login.logout' | translate }}</button></li>
        </ul>

        <a class="link-btn in-nav-mobile-link chat-btn" (click)="accountUser.chat_unread_messages = 0" style="cursor: pointer;" [routerLink]="['/chat/messages']">
            <i class="bi bi-chat"></i>
            <span class="badge-count" *ngIf="accountUser.getChatUnreadMessagesCount() > 0">{{ accountUser.getChatUnreadMessagesCount() }}</span>
        </a>

    </div>
</div>

<div *ngIf="!hideHeader" class="alert-container" (click)="displayMessage=undefined">
    <div role="alert" *ngIf="displayMessage && displayMessage.type == 'success'">

        <div class="rounded-md bg-green-50 p-4 border-green-500 border">

            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3">
                    <div class="text-sm text-green-700">
                        <p>{{ displayMessage.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div role="alert" *ngIf="displayMessage && displayMessage.type == 'error'">
        <div class="rounded-md bg-red-50 p-4 border-red-500 border">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3">
                    <div class="text-sm text-red-700">
                        <p>{{ displayMessage.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div role="alert" *ngIf="displayMessage && displayMessage.type == 'warning'">
        <div class="rounded-md bg-yellow-50 p-4 border-yellow-500 border">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3">
                    <div class="text-sm text-yellow-700">
                        <p>{{ displayMessage.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div role="alert" *ngIf="displayMessage && displayMessage.type == 'info'">
        <div class="rounded-md bg-blue-50 p-4 border-blue-500 border">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">{{ displayMessage.text }}</p>
                </div>
            </div>
        </div>

    </div>
</div>

<!--
<app-modal #messenger [size]="'lg'">
    <app-messenger [showConversations]="true" #messengerComponent></app-messenger>
</app-modal>
-->

<app-modal #notificationsModal [size]="'md'">
    <div appInfiniteScroll (bottomReached)="nList.onScrollDirectiveReachedBottom()" class="h-auto max-h-[80vh] overflow-y-auto">
        <app-notification-list #nList *ngIf="notificationsModal.isOpen() && accountUser" [setAuId]="accountUser.getId()"></app-notification-list>
    </div>
</app-modal>

<app-modal #addCompanyModal [size]="'lg'">
    <!--
    <app-company-create-form *ngIf="addCompanyModal.isOpen()" (created)="closeAddCompanyModal()"></app-company-create-form>
    -->
</app-modal>

<app-modal #loginModal [size]="'md'">
    <app-login *ngIf="loginModal.isOpen()" (loginSuccessful)="closeLoginModal()"></app-login>

    <div style="display: flex; align-items: center; margin-top: 40px;">
        <span>{{ 'account.no-account' | translate }}?</span>
        <button type="button" class="btn btn-outline-primary" style="margin-left: 10px" (click)="openSignupModal()">{{ 'signup.sign-up' | translate }}</button>
    </div>

    <!--
        <div class="signUpUsp">
        <strong>{{ 'signup.personal-title' | translate }}</strong>
        <div>{{ 'signup.personal-text' | translate }}</div>
        </div>
        <div class="signUpUsp">
        <strong>{{ 'signup.business-title' | translate }}</strong>
        <div>{{ 'signup.business-text' | translate }}</div>
        </div>
    -->
</app-modal>

<app-modal #signupModal [size]="'md'">
    <app-signup *ngIf="signupModal.isOpen()"></app-signup>

    <!--
        <div class="signUpUsp" style="margin-top: 40px;">
        <strong>{{ 'signup.personal-title' | translate }}</strong>
        <div>{{ 'signup.personal-text' | translate }}</div>
        </div>
        <div class="signUpUsp">
        <strong>{{ 'signup.business-title' | translate }}</strong>
        <div>{{ 'signup.business-text' | translate }}</div>
        </div>
    -->
</app-modal>

<app-modal #signupCompanyModal [size]="'md'">
    <h2>{{ 'header.post-a-job-title' | translate }}</h2>

    <br />

    <div>
        {{ 'header.post-a-job-description' | translate }}
    </div>

    <br />

    <app-signup *ngIf="signupCompanyModal.isOpen()" [isCompany]="true" [showLogin]="true"></app-signup>
</app-modal>

<app-modal #menuModal [size]="'md'">
    <!--
    <app-menu *ngIf="accountUser && menuModal.isOpen()" [accountUser]="accountUser" (closeMenu)="closeMenuModal()"></app-menu>
    -->
</app-modal>

<app-modal #forBusinessModal [size]="'lg'">
    <app-for-business-popup *ngIf="forBusinessModal.isOpen()" (closePopup)="forBusinessModal.close()"></app-for-business-popup>
</app-modal>

<app-modal #shareProfileModule [size]="'md'">
    <app-profile-share *ngIf="shareProfileModule.isOpen()" [profileAccountUser]="accountUser"></app-profile-share>
</app-modal>
